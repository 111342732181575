export const languageConfig = {
  'de-at': 'de',
  'de-be': 'de',
  'de-ch': 'de',
  'de-de': 'de',
  'de-lu': 'de',
  'en-cz': 'en_US',
  'en-dk': 'en_US',
  'en-fi': 'en_US',
  'en-gb': 'en_GB',
  'en-ie': 'en_US',
  'en-se': 'en_US',
  'en-us': 'en_US',
  'es-es': 'es',
  'fr-be': 'fr',
  'fr-ch': 'fr',
  'fr-fr': 'fr',
  'fr-lu': 'fr',
  'it-ch': 'it',
  'it-it': 'it',
  'nl-be': 'nl_NL',
  'nl-nl': 'nl_NL',
  'pl-pl': 'pl',
  'pt-pt': 'pt_PT'
}
