import type { ChatProvider } from '#types/plugin/chat'
import type { LocaleCode } from '#types/locale'
import type { MIAWConfig, MIAWEventName, PrechatFields, ScriptLoadResult } from '#types/plugin/enhancedLivechat'

export default (config: MIAWConfig): ChatProvider => {
  const { $feature, $gtm, $locale } = useNuxtApp()
  const {
    baseUrlBot,
    baseUrlLiveAgent,
    bootstrapUrlBot,
    bootstrapUrlLiveAgent,
    orgId,
    scrtUrl
  } = $feature.configLivechat
  const available = ref<boolean>(false)
  const { targetEnv } = useRuntimeConfig().public
  const isBot = config.botLocales.includes($locale)

  let initializationComplete = false
  let timerId

  const languageToSkillId = (locale: LocaleCode) => {
    const baseLanguage = locale.split('-')[0]
    const languageMap = {
      cs: 'Chat_Czech',
      da: 'Chat_Danish',
      de: 'Chat_German',
      en: 'Chat_English',
      es: 'Chat_Spanish',
      fr: 'Chat_French',
      it: 'Chat_Italian',
      nl: 'Chat_Dutch',
      pl: 'Chat_Polish',
      pt: 'Chat_Portuguese',
      sv: 'Chat_Swedish',
      // Add other languages as needed
    }

    return languageMap[baseLanguage] || 'Chat_English'
  }

  const loadCss = () => {
    const cssPath = config.cssPath
    try {
      if (!document.querySelector(`link[href="${cssPath}"]`)) {
        useHead({
          link: () => [
            {
              rel: 'stylesheet',
              type: 'text/css',
              href: cssPath,
              once: true
            }
          ],
        })
      }
    }
    catch (err) {
      console.warn('failed to load livechat css', err)
    }
  }

  const setPrechatFields = async (): Promise<void> => {
    const browserLanguage = navigator.language
    const response = await fetch('https://api.ipify.org?format=json')
    const { ip } = await response.json()
    const loadPageDataEvent = window.dataLayer?.find((event) => event.event === 'loadPageData')
    const { countryLanguage: currentLang, countryCode: currentCountry } = loadPageDataEvent?.page

    if (window.embeddedservice_bootstrap?.prechatAPI) {
      const fields: PrechatFields = {
        Current_Bot_Language: config.languageConfig[$locale],
        Current_Bot_Skill_Id: languageToSkillId($locale),
        Brand: config.brand,
        Chat_Origin: `${currentCountry?.toLowerCase()}-${currentLang?.toLowerCase()}`,
        BrowserLanguage: browserLanguage,
        Visitor_s_IP_Address: ip
      }

      window.embeddedservice_bootstrap.prechatAPI.setHiddenPrechatFields(fields)

      // keeping this debugger here for now as it's the only way to access hidden fields
      window._miawDebug = {
        lastUpdate: new Date().toISOString(),
        fields
      }
    }
  }

  const initializeLiveChatEventTracking = (): void => {
    const events = {
      onEmbeddedMessagingWindowMaximized: () => $gtm?.push('livechat.onAction', 'Displayed'),
      onEmbeddedMessagingConversationClosed: () => $gtm?.push('livechat.onAction', 'Closed', false),
      onEmbeddedMessagingConversationRouted: () => $gtm?.push('livechat.onAction', 'Agent Transfer Completed'),
      onEmbeddedMessagingConversationStarted: () => {
        $gtm?.push('livechat.onAction', 'Started', false)
        $gtm?.push('livechat.onAction', 'Chatbot Engaged')
      }
    }

    Object.entries(events).forEach(([eventName, handler]) =>
      window.addEventListener(eventName as MIAWEventName, handler))
  }

  const handleMessagingReady = async (): Promise<void> => {
    try {
      await setPrechatFields()
      available.value = true
    }
    catch (error) {
      console.error('Error fetching IP:', error)
    }
  }

  const initializeMessaging = (): void => {
    const loadPageDataEvent = window.dataLayer?.find((event) => event.event === 'loadPageData')
    const isCookieAccepted = hasCookieConsent(['required', 'performanceAndAnalytics', 'functional', 'targeting'])

    if (initializationComplete) return

    // Only load chat if cookies are accepted
    if (loadPageDataEvent && isCookieAccepted) {
      clearInterval(timerId)
      loadCss()

      try {
        const bootstrap = window.embeddedservice_bootstrap

        if (!bootstrap)
          throw new Error('Embedded service bootstrap not loaded')

        bootstrap.settings.language = config.languageConfig[$locale]
        bootstrap.settings.chatButtonPosition = config.chatButtonPosition ?? '4rem,3rem'
        window.addEventListener('onEmbeddedMessagingReady', handleMessagingReady)

        bootstrap.init(
          orgId[targetEnv],
          isBot ? 'MIAW_Vans' : 'MIAW_Vans_Live_Chats',
          isBot ? baseUrlBot![targetEnv] : baseUrlLiveAgent![targetEnv],
          {
            scrt2URL: scrtUrl![targetEnv]
          }
        )
        initializationComplete = true
      }
      catch (err) {
        console.error('Error loading Embedded Messaging:', err)
        throw err
      }
    }
  }

  const loadBootstrapScript = async (): Promise<ScriptLoadResult> => {
    try {
      window.initEmbeddedMessaging = initializeMessaging
      const result = await loadScript(isBot ? bootstrapUrlBot![targetEnv] : bootstrapUrlLiveAgent![targetEnv], {
        'data-miaw-chat': 'true'
      }, {
        type: 'text/javascript'
      }) as ScriptLoadResult

      if (!result.loaded)
        throw new Error('Failed to load MIAW script')
      return result
    }
    catch (error) {
      console.error('Error loading MIAW script:', error)
      throw error
    }
  }

  const init = async (): Promise<void> => {
    try {
      await loadBootstrapScript()
      initializeLiveChatEventTracking()

      timerId = window.setInterval(initializeMessaging, 5000)
    }
    catch (error) {
      console.error('Failed to initialize MIAW chat:', error)
    }
  }

  onUnmounted(() => {
    if (timerId !== null)
      window.clearInterval(timerId)
  })

  init()

  return {
    provide: {
      chat: {
        open: (): void => {
          if (window.embeddedservice_bootstrap?.utilAPI)
            window.embeddedservice_bootstrap.utilAPI.launchChat()
        },
        available
      }
    }
  }
}
