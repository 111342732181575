import { languageConfig } from '../../../../config/integrations/livechat'
import type { ChatPlugin } from '#types/plugin/chat'
import enhancedLiveChat from '#core/integrations/enhanced-live-chat'

export default defineNuxtPlugin<{ chat: ChatPlugin }>(() => {
  const { configLivechat, enableChat } = useFeatureFlags()

  if (!enableChat) {
    return {
      provide: {
        chat: {
          open: () => console.warn('Chat is disabled by feature flag'),
          available: ref(false)
        }
      }
    }
  }

  if (configLivechat.enabled) {
    return enhancedLiveChat({
      brand: 'Vans',
      botLocales: ['en-gb', 'de-de', 'it-it', 'fr-fr', 'es-es', 'nl-nl'],
      languageConfig,
      cssPath: '/livechat/css/LiveChatCodeCanvasVansEMEA.css',
    })
  }

  // No other chat provider for Vans
  return {
    provide: {
      chat: {
        open: () => console.warn('Chat is not implemented for Vans EMEA.'),
        available: ref(false)
      }
    }
  }
})
