import { default as _91_46_46_46slug_93iFzx9XNSoRMeta } from "/opt/build/repo/domains/content/pages/[...slug].vue?macro=true";
import { default as _400qhJBXBrSM1Meta } from "/opt/build/repo/domains/core/pages/400.vue?macro=true";
import { default as _401ItfQ97RD80Meta } from "/opt/build/repo/domains/core/pages/401.vue?macro=true";
import { default as _403HiPxJLeGtmMeta } from "/opt/build/repo/domains/core/pages/403.vue?macro=true";
import { default as _404G5FYF8c3euMeta } from "/opt/build/repo/domains/core/pages/404.vue?macro=true";
import { default as _500xtnzTA4IXTMeta } from "/opt/build/repo/domains/core/pages/500.vue?macro=true";
import { default as add_45billing_45addressQgO7FOoY4jMeta } from "/opt/build/repo/domains/commerce/pages/account/address/add-billing-address.vue?macro=true";
import { default as add_45shipping_45addressnECrwjt1RaMeta } from "/opt/build/repo/domains/commerce/pages/account/address/add-shipping-address.vue?macro=true";
import { default as index7pHJ5jHdKVMeta } from "/opt/build/repo/domains/commerce/pages/account/address/index.vue?macro=true";
import { default as addpYBOtZ12f1Meta } from "/opt/build/repo/domains/commerce/pages/account/credit-cards/add.vue?macro=true";
import { default as indexmudLHLDe4UMeta } from "/opt/build/repo/domains/commerce/pages/account/credit-cards/index.vue?macro=true";
import { default as gift_45cards7uHCsfDhVHMeta } from "/opt/build/repo/domains/commerce/pages/account/gift-cards.vue?macro=true";
import { default as indexc4s1RaNYYtMeta } from "/opt/build/repo/domains/commerce/pages/account/index.vue?macro=true";
import { default as loyaltyE9m8Qwcn0gMeta } from "/opt/build/repo/domains/commerce/pages/account/loyalty.vue?macro=true";
import { default as _91_46_46_46id_93aX78vf4ysSMeta } from "/opt/build/repo/domains/commerce/pages/account/order-details/[...id].vue?macro=true";
import { default as order_45historyasXzwY1JphMeta } from "/opt/build/repo/domains/commerce/pages/account/order-history.vue?macro=true";
import { default as profilegEaRvs8BcLMeta } from "/opt/build/repo/domains/commerce/pages/account/profile.vue?macro=true";
import { default as accounthlDnJ5OiYzMeta } from "/opt/build/repo/domains/commerce/pages/account.vue?macro=true";
import { default as _91slug_938pmL5oygQYMeta } from "/opt/build/repo/domains/commerce/pages/articles/[slug].vue?macro=true";
import { default as _91_46_46_46slug_93bprO6UBVz6Meta } from "/opt/build/repo/domains/commerce/pages/c/[...slug].vue?macro=true";
import { default as cartWuVmEXFYumMeta } from "/opt/build/repo/domains/commerce/pages/cart.vue?macro=true";
import { default as checkoutpVgIzhHGMpMeta } from "/opt/build/repo/domains/commerce/pages/checkout.vue?macro=true";
import { default as previewbsjXZUsg2WMeta } from "/opt/build/repo/domains/content/pages/cms/preview.vue?macro=true";
import { default as singlemJA5aUzhqfMeta } from "/opt/build/repo/domains/content/pages/cms/showcase/single.vue?macro=true";
import { default as splittWKYwg5sChMeta } from "/opt/build/repo/domains/content/pages/cms/showcase/split.vue?macro=true";
import { default as viewerZzlU0ZTRMuMeta } from "/opt/build/repo/domains/content/pages/cms/showcase/viewer.vue?macro=true";
import { default as _91model_93KQkayHoyi0Meta } from "/opt/build/repo/brands/vans/pages/customize/[model].vue?macro=true";
import { default as indexiKNT6GA6ZPMeta } from "/opt/build/repo/brands/vans/pages/customize/index.vue?macro=true";
import { default as _91model_93nskdsp0jc9Meta } from "/opt/build/repo/brands/vans/pages/customizer/[model].vue?macro=true";
import { default as declaration_45of_45conformityECkioIPuNFMeta } from "/opt/build/repo/brands/vans/regions/emea/pages/declaration-of-conformity.vue?macro=true";
import { default as familyKq62STl0OpMeta } from "/opt/build/repo/brands/vans/regions/emea/pages/family.vue?macro=true";
import { default as favoritesZ6Qjd8m6kCMeta } from "/opt/build/repo/domains/commerce/pages/favorites.vue?macro=true";
import { default as _91productId_93eUl1QYzOnNMeta } from "/opt/build/repo/domains/commerce/pages/find-in-store/[productId].vue?macro=true";
import { default as forgot_45passwordpB0K6pl4D5Meta } from "/opt/build/repo/domains/commerce/pages/forgot-password.vue?macro=true";
import { default as gift_45cardtmoMyoPxmoMeta } from "/opt/build/repo/domains/commerce/pages/gift-card.vue?macro=true";
import { default as feature_45flagsAEnkTJXV99Meta } from "/opt/build/repo/domains/core/pages/healthcheck/feature-flags.vue?macro=true";
import { default as indexYNInD0yqeIMeta } from "/opt/build/repo/domains/core/pages/healthcheck/index.vue?macro=true";
import { default as mockszaKVBZ4zkrMeta } from "/opt/build/repo/domains/core/pages/healthcheck/mocks.vue?macro=true";
import { default as static_45pagesEkYVy0JFNAMeta } from "/opt/build/repo/domains/core/pages/healthcheck/static-pages.vue?macro=true";
import { default as statusDsh9ErRLnKMeta } from "/opt/build/repo/domains/core/pages/healthcheck/status.vue?macro=true";
import { default as translationsK89azthgVZMeta } from "/opt/build/repo/domains/core/pages/healthcheck/translations.vue?macro=true";
import { default as index3bev93goYsMeta } from "/opt/build/repo/domains/commerce/pages/help/[slug]/[child_slug]/index.vue?macro=true";
import { default as indexRABbflB1hQMeta } from "/opt/build/repo/domains/commerce/pages/help/[slug]/index.vue?macro=true";
import { default as all_45topicsVt7jfuQxwoMeta } from "/opt/build/repo/domains/commerce/pages/help/all-topics.vue?macro=true";
import { default as contact_45uswxM9cdDGLjMeta } from "/opt/build/repo/domains/commerce/pages/help/contact-us.vue?macro=true";
import { default as faqk4PzR6ZMrqMeta } from "/opt/build/repo/brands/vans/regions/emea/pages/help/faq.vue?macro=true";
import { default as indexEeWJSz6E2lMeta } from "/opt/build/repo/domains/commerce/pages/help/index.vue?macro=true";
import { default as size_45chartsYRYEHRD8EGMeta } from "/opt/build/repo/brands/vans/pages/help/size-charts.vue?macro=true";
import { default as help60JKLo08atMeta } from "/opt/build/repo/domains/commerce/pages/help.vue?macro=true";
import { default as location_45selectorRcxhmVvYElMeta } from "/opt/build/repo/domains/commerce/pages/location-selector.vue?macro=true";
import { default as lower_45envs7iyfzqrC1tMeta } from "/opt/build/repo/domains/core/pages/lower-envs.vue?macro=true";
import { default as newsletter_45subscriberla6JKAnMLBMeta } from "/opt/build/repo/brands/vans/regions/emea/pages/newsletter-subscriber.vue?macro=true";
import { default as order_45confirmation2zFGjSoOQrMeta } from "/opt/build/repo/domains/commerce/pages/order-confirmation.vue?macro=true";
import { default as order_45details2hEewW5V7XMeta } from "/opt/build/repo/domains/commerce/pages/order-details.vue?macro=true";
import { default as order_45statusSEiCEG4MyaMeta } from "/opt/build/repo/domains/commerce/pages/order-status.vue?macro=true";
import { default as otw_45by_45vansdvfuOelxAIMeta } from "/opt/build/repo/brands/vans/regions/emea/pages/otw-by-vans.vue?macro=true";
import { default as gift_45card_45_91slug_93aBTCyRnHqOMeta } from "/opt/build/repo/domains/commerce/pages/p/[...catalog]/gift-card-[slug].vue?macro=true";
import { default as _91_46_46_46slug_938XUmtihIQsMeta } from "/opt/build/repo/domains/commerce/pages/p/[...slug].vue?macro=true";
import { default as pillowheatec6W0gUBGYMeta } from "/opt/build/repo/brands/vans/regions/emea/pages/pillowheat.vue?macro=true";
import { default as poslogonP3pXPibJHEMeta } from "/opt/build/repo/brands/vans/pages/poslogon.vue?macro=true";
import { default as reset_45passwordhnp3THUaMLMeta } from "/opt/build/repo/domains/commerce/pages/reset-password.vue?macro=true";
import { default as return_45labelNxjSTzFdk0Meta } from "/opt/build/repo/domains/commerce/pages/return-label.vue?macro=true";
import { default as _91productId_93AKFxWRSIxWMeta } from "/opt/build/repo/domains/commerce/pages/reviews/[productId].vue?macro=true";
import { default as index0fogzGIqf7Meta } from "/opt/build/repo/domains/content/pages/sandbox/cms/index.vue?macro=true";
import { default as indexOjBbltyRRZMeta } from "/opt/build/repo/domains/content/pages/sandbox/dialogs/index.vue?macro=true";
import { default as indexYytI4mQazyMeta } from "/opt/build/repo/domains/content/pages/sandbox/monetate/index.vue?macro=true";
import { default as dashboardVdaazQkT64Meta } from "/opt/build/repo/domains/commerce/pages/sandbox/preview/account/dashboard.vue?macro=true";
import { default as profile1OppCRoqrFMeta } from "/opt/build/repo/domains/commerce/pages/sandbox/preview/account/profile.vue?macro=true";
import { default as checkoutNnt8GaEkPWMeta } from "/opt/build/repo/domains/commerce/pages/sandbox/preview/checkout.vue?macro=true";
import { default as order_45confirmationQ6TY8ONuoZMeta } from "/opt/build/repo/domains/commerce/pages/sandbox/preview/order-confirmation.vue?macro=true";
import { default as articlesU210RWMfaRMeta } from "/opt/build/repo/domains/commerce/pages/search/articles.vue?macro=true";
import { default as indexKjUEhFFlYyMeta } from "/opt/build/repo/domains/commerce/pages/search/index.vue?macro=true";
import { default as searchxnc5Nq4Lb2Meta } from "/opt/build/repo/domains/commerce/pages/search.vue?macro=true";
import { default as sign_45invOXFFa0gzPMeta } from "/opt/build/repo/domains/commerce/pages/sign-in.vue?macro=true";
import { default as since_4566JbpcI0GUL7Meta } from "/opt/build/repo/brands/vans/pages/since-66.vue?macro=true";
import { default as size_45chart0T7nwjfX8oMeta } from "/opt/build/repo/domains/commerce/pages/size-chart.vue?macro=true";
import { default as footerAUtE5rq5gqMeta } from "/opt/build/repo/domains/commerce/pages/soci/footer.vue?macro=true";
import { default as frame382Y1ymrZaMeta } from "/opt/build/repo/domains/commerce/pages/soci/frame.vue?macro=true";
import { default as headerR4riDyN3LsMeta } from "/opt/build/repo/domains/commerce/pages/soci/header.vue?macro=true";
import { default as store_45locatortqupMGLkzCMeta } from "/opt/build/repo/domains/commerce/pages/store-locator.vue?macro=true";
import { default as student_45discountBs4TB40tHtMeta } from "/opt/build/repo/brands/vans/regions/emea/pages/student-discount.vue?macro=true";
import { default as sustainabilitylibbGBqpSYMeta } from "/opt/build/repo/brands/vans/pages/sustainability.vue?macro=true";
import { default as _91slug_939yy7wVfmBHMeta } from "/opt/build/repo/brands/vans/pages/tag/[slug].vue?macro=true";
import { default as westendxDDwHHaE22Meta } from "/opt/build/repo/brands/vans/regions/emea/pages/westend.vue?macro=true";
import { default as write_45a_45reviewb5NDvBQXhOMeta } from "/opt/build/repo/domains/commerce/pages/write-a-review.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93iFzx9XNSoRMeta?.name ?? "slug",
    path: _91_46_46_46slug_93iFzx9XNSoRMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93iFzx9XNSoRMeta || {},
    alias: _91_46_46_46slug_93iFzx9XNSoRMeta?.alias || [],
    redirect: _91_46_46_46slug_93iFzx9XNSoRMeta?.redirect,
    component: () => import("/opt/build/repo/domains/content/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _400qhJBXBrSM1Meta?.name ?? "400",
    path: _400qhJBXBrSM1Meta?.path ?? "/400",
    meta: _400qhJBXBrSM1Meta || {},
    alias: _400qhJBXBrSM1Meta?.alias || [],
    redirect: _400qhJBXBrSM1Meta?.redirect,
    component: () => import("/opt/build/repo/domains/core/pages/400.vue").then(m => m.default || m)
  },
  {
    name: _401ItfQ97RD80Meta?.name ?? "401",
    path: _401ItfQ97RD80Meta?.path ?? "/401",
    meta: _401ItfQ97RD80Meta || {},
    alias: _401ItfQ97RD80Meta?.alias || [],
    redirect: _401ItfQ97RD80Meta?.redirect,
    component: () => import("/opt/build/repo/domains/core/pages/401.vue").then(m => m.default || m)
  },
  {
    name: _403HiPxJLeGtmMeta?.name ?? "403",
    path: _403HiPxJLeGtmMeta?.path ?? "/403",
    meta: _403HiPxJLeGtmMeta || {},
    alias: _403HiPxJLeGtmMeta?.alias || [],
    redirect: _403HiPxJLeGtmMeta?.redirect,
    component: () => import("/opt/build/repo/domains/core/pages/403.vue").then(m => m.default || m)
  },
  {
    name: _404G5FYF8c3euMeta?.name ?? "404",
    path: _404G5FYF8c3euMeta?.path ?? "/404",
    meta: _404G5FYF8c3euMeta || {},
    alias: _404G5FYF8c3euMeta?.alias || [],
    redirect: _404G5FYF8c3euMeta?.redirect,
    component: () => import("/opt/build/repo/domains/core/pages/404.vue").then(m => m.default || m)
  },
  {
    name: _500xtnzTA4IXTMeta?.name ?? "500",
    path: _500xtnzTA4IXTMeta?.path ?? "/500",
    meta: _500xtnzTA4IXTMeta || {},
    alias: _500xtnzTA4IXTMeta?.alias || [],
    redirect: _500xtnzTA4IXTMeta?.redirect,
    component: () => import("/opt/build/repo/domains/core/pages/500.vue").then(m => m.default || m)
  },
  {
    name: accounthlDnJ5OiYzMeta?.name ?? undefined,
    path: accounthlDnJ5OiYzMeta?.path ?? "/account",
    meta: accounthlDnJ5OiYzMeta || {},
    alias: accounthlDnJ5OiYzMeta?.alias || [],
    redirect: accounthlDnJ5OiYzMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: add_45billing_45addressQgO7FOoY4jMeta?.name ?? "account-address-add-billing-address",
    path: add_45billing_45addressQgO7FOoY4jMeta?.path ?? "address/add-billing-address",
    meta: add_45billing_45addressQgO7FOoY4jMeta || {},
    alias: add_45billing_45addressQgO7FOoY4jMeta?.alias || [],
    redirect: add_45billing_45addressQgO7FOoY4jMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/account/address/add-billing-address.vue").then(m => m.default || m)
  },
  {
    name: add_45shipping_45addressnECrwjt1RaMeta?.name ?? "account-address-add-shipping-address",
    path: add_45shipping_45addressnECrwjt1RaMeta?.path ?? "address/add-shipping-address",
    meta: add_45shipping_45addressnECrwjt1RaMeta || {},
    alias: add_45shipping_45addressnECrwjt1RaMeta?.alias || [],
    redirect: add_45shipping_45addressnECrwjt1RaMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/account/address/add-shipping-address.vue").then(m => m.default || m)
  },
  {
    name: index7pHJ5jHdKVMeta?.name ?? "account-address",
    path: index7pHJ5jHdKVMeta?.path ?? "address",
    meta: index7pHJ5jHdKVMeta || {},
    alias: index7pHJ5jHdKVMeta?.alias || [],
    redirect: index7pHJ5jHdKVMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/account/address/index.vue").then(m => m.default || m)
  },
  {
    name: addpYBOtZ12f1Meta?.name ?? "account-credit-cards-add",
    path: addpYBOtZ12f1Meta?.path ?? "credit-cards/add",
    meta: addpYBOtZ12f1Meta || {},
    alias: addpYBOtZ12f1Meta?.alias || [],
    redirect: addpYBOtZ12f1Meta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/account/credit-cards/add.vue").then(m => m.default || m)
  },
  {
    name: indexmudLHLDe4UMeta?.name ?? "account-credit-cards",
    path: indexmudLHLDe4UMeta?.path ?? "credit-cards",
    meta: indexmudLHLDe4UMeta || {},
    alias: indexmudLHLDe4UMeta?.alias || [],
    redirect: indexmudLHLDe4UMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/account/credit-cards/index.vue").then(m => m.default || m)
  },
  {
    name: gift_45cards7uHCsfDhVHMeta?.name ?? "account-gift-cards",
    path: gift_45cards7uHCsfDhVHMeta?.path ?? "gift-cards",
    meta: gift_45cards7uHCsfDhVHMeta || {},
    alias: gift_45cards7uHCsfDhVHMeta?.alias || [],
    redirect: gift_45cards7uHCsfDhVHMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/account/gift-cards.vue").then(m => m.default || m)
  },
  {
    name: indexc4s1RaNYYtMeta?.name ?? "account",
    path: indexc4s1RaNYYtMeta?.path ?? "",
    meta: indexc4s1RaNYYtMeta || {},
    alias: indexc4s1RaNYYtMeta?.alias || [],
    redirect: indexc4s1RaNYYtMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: loyaltyE9m8Qwcn0gMeta?.name ?? "account-loyalty",
    path: loyaltyE9m8Qwcn0gMeta?.path ?? "loyalty",
    meta: loyaltyE9m8Qwcn0gMeta || {},
    alias: loyaltyE9m8Qwcn0gMeta?.alias || [],
    redirect: loyaltyE9m8Qwcn0gMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/account/loyalty.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46id_93aX78vf4ysSMeta?.name ?? "account-order-details-id",
    path: _91_46_46_46id_93aX78vf4ysSMeta?.path ?? "order-details/:id(.*)*",
    meta: _91_46_46_46id_93aX78vf4ysSMeta || {},
    alias: _91_46_46_46id_93aX78vf4ysSMeta?.alias || [],
    redirect: _91_46_46_46id_93aX78vf4ysSMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/account/order-details/[...id].vue").then(m => m.default || m)
  },
  {
    name: order_45historyasXzwY1JphMeta?.name ?? "account-order-history",
    path: order_45historyasXzwY1JphMeta?.path ?? "order-history",
    meta: order_45historyasXzwY1JphMeta || {},
    alias: order_45historyasXzwY1JphMeta?.alias || [],
    redirect: order_45historyasXzwY1JphMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/account/order-history.vue").then(m => m.default || m)
  },
  {
    name: profilegEaRvs8BcLMeta?.name ?? "account-profile",
    path: profilegEaRvs8BcLMeta?.path ?? "profile",
    meta: profilegEaRvs8BcLMeta || {},
    alias: profilegEaRvs8BcLMeta?.alias || [],
    redirect: profilegEaRvs8BcLMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/account/profile.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91slug_938pmL5oygQYMeta?.name ?? "articles-slug",
    path: _91slug_938pmL5oygQYMeta?.path ?? "/articles/:slug()",
    meta: _91slug_938pmL5oygQYMeta || {},
    alias: _91slug_938pmL5oygQYMeta?.alias || [],
    redirect: _91slug_938pmL5oygQYMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/articles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93bprO6UBVz6Meta?.name ?? "c-slug",
    path: _91_46_46_46slug_93bprO6UBVz6Meta?.path ?? "/c/:slug(.*)*",
    meta: _91_46_46_46slug_93bprO6UBVz6Meta || {},
    alias: _91_46_46_46slug_93bprO6UBVz6Meta?.alias || [],
    redirect: _91_46_46_46slug_93bprO6UBVz6Meta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/c/[...slug].vue").then(m => m.default || m)
  },
  {
    name: cartWuVmEXFYumMeta?.name ?? "cart",
    path: cartWuVmEXFYumMeta?.path ?? "/cart",
    meta: cartWuVmEXFYumMeta || {},
    alias: cartWuVmEXFYumMeta?.alias || [],
    redirect: cartWuVmEXFYumMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: checkoutpVgIzhHGMpMeta?.name ?? "checkout",
    path: checkoutpVgIzhHGMpMeta?.path ?? "/checkout",
    meta: checkoutpVgIzhHGMpMeta || {},
    alias: checkoutpVgIzhHGMpMeta?.alias || [],
    redirect: checkoutpVgIzhHGMpMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/checkout.vue").then(m => m.default || m)
  },
  {
    name: previewbsjXZUsg2WMeta?.name ?? "cms-preview",
    path: previewbsjXZUsg2WMeta?.path ?? "/cms/preview",
    meta: previewbsjXZUsg2WMeta || {},
    alias: previewbsjXZUsg2WMeta?.alias || [],
    redirect: previewbsjXZUsg2WMeta?.redirect,
    component: () => import("/opt/build/repo/domains/content/pages/cms/preview.vue").then(m => m.default || m)
  },
  {
    name: singlemJA5aUzhqfMeta?.name ?? "cms-showcase-single",
    path: singlemJA5aUzhqfMeta?.path ?? "/cms/showcase/single",
    meta: singlemJA5aUzhqfMeta || {},
    alias: singlemJA5aUzhqfMeta?.alias || [],
    redirect: singlemJA5aUzhqfMeta?.redirect,
    component: () => import("/opt/build/repo/domains/content/pages/cms/showcase/single.vue").then(m => m.default || m)
  },
  {
    name: splittWKYwg5sChMeta?.name ?? "cms-showcase-split",
    path: splittWKYwg5sChMeta?.path ?? "/cms/showcase/split",
    meta: splittWKYwg5sChMeta || {},
    alias: splittWKYwg5sChMeta?.alias || [],
    redirect: splittWKYwg5sChMeta?.redirect,
    component: () => import("/opt/build/repo/domains/content/pages/cms/showcase/split.vue").then(m => m.default || m)
  },
  {
    name: viewerZzlU0ZTRMuMeta?.name ?? "cms-showcase-viewer",
    path: viewerZzlU0ZTRMuMeta?.path ?? "/cms/showcase/viewer",
    meta: viewerZzlU0ZTRMuMeta || {},
    alias: viewerZzlU0ZTRMuMeta?.alias || [],
    redirect: viewerZzlU0ZTRMuMeta?.redirect,
    component: () => import("/opt/build/repo/domains/content/pages/cms/showcase/viewer.vue").then(m => m.default || m)
  },
  {
    name: _91model_93KQkayHoyi0Meta?.name ?? "customize-model",
    path: _91model_93KQkayHoyi0Meta?.path ?? "/customize/:model()",
    meta: _91model_93KQkayHoyi0Meta || {},
    alias: _91model_93KQkayHoyi0Meta?.alias || [],
    redirect: _91model_93KQkayHoyi0Meta?.redirect,
    component: () => import("/opt/build/repo/brands/vans/pages/customize/[model].vue").then(m => m.default || m)
  },
  {
    name: indexiKNT6GA6ZPMeta?.name ?? "customize",
    path: indexiKNT6GA6ZPMeta?.path ?? "/customize",
    meta: indexiKNT6GA6ZPMeta || {},
    alias: indexiKNT6GA6ZPMeta?.alias || [],
    redirect: indexiKNT6GA6ZPMeta?.redirect,
    component: () => import("/opt/build/repo/brands/vans/pages/customize/index.vue").then(m => m.default || m)
  },
  {
    name: _91model_93nskdsp0jc9Meta?.name ?? "customizer-model",
    path: _91model_93nskdsp0jc9Meta?.path ?? "/customizer/:model()",
    meta: _91model_93nskdsp0jc9Meta || {},
    alias: _91model_93nskdsp0jc9Meta?.alias || [],
    redirect: _91model_93nskdsp0jc9Meta?.redirect,
    component: () => import("/opt/build/repo/brands/vans/pages/customizer/[model].vue").then(m => m.default || m)
  },
  {
    name: declaration_45of_45conformityECkioIPuNFMeta?.name ?? "declaration-of-conformity",
    path: declaration_45of_45conformityECkioIPuNFMeta?.path ?? "/declaration-of-conformity",
    meta: declaration_45of_45conformityECkioIPuNFMeta || {},
    alias: declaration_45of_45conformityECkioIPuNFMeta?.alias || [],
    redirect: declaration_45of_45conformityECkioIPuNFMeta?.redirect,
    component: () => import("/opt/build/repo/brands/vans/regions/emea/pages/declaration-of-conformity.vue").then(m => m.default || m)
  },
  {
    name: familyKq62STl0OpMeta?.name ?? "family",
    path: familyKq62STl0OpMeta?.path ?? "/family",
    meta: familyKq62STl0OpMeta || {},
    alias: familyKq62STl0OpMeta?.alias || [],
    redirect: familyKq62STl0OpMeta?.redirect,
    component: () => import("/opt/build/repo/brands/vans/regions/emea/pages/family.vue").then(m => m.default || m)
  },
  {
    name: favoritesZ6Qjd8m6kCMeta?.name ?? "favorites",
    path: favoritesZ6Qjd8m6kCMeta?.path ?? "/favorites",
    meta: favoritesZ6Qjd8m6kCMeta || {},
    alias: favoritesZ6Qjd8m6kCMeta?.alias || [],
    redirect: favoritesZ6Qjd8m6kCMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/favorites.vue").then(m => m.default || m)
  },
  {
    name: _91productId_93eUl1QYzOnNMeta?.name ?? "find-in-store-productId",
    path: _91productId_93eUl1QYzOnNMeta?.path ?? "/find-in-store/:productId()",
    meta: _91productId_93eUl1QYzOnNMeta || {},
    alias: _91productId_93eUl1QYzOnNMeta?.alias || [],
    redirect: _91productId_93eUl1QYzOnNMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/find-in-store/[productId].vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordpB0K6pl4D5Meta?.name ?? "forgot-password",
    path: forgot_45passwordpB0K6pl4D5Meta?.path ?? "/forgot-password",
    meta: forgot_45passwordpB0K6pl4D5Meta || {},
    alias: forgot_45passwordpB0K6pl4D5Meta?.alias || [],
    redirect: forgot_45passwordpB0K6pl4D5Meta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: gift_45cardtmoMyoPxmoMeta?.name ?? "gift-card",
    path: gift_45cardtmoMyoPxmoMeta?.path ?? "/gift-card",
    meta: gift_45cardtmoMyoPxmoMeta || {},
    alias: gift_45cardtmoMyoPxmoMeta?.alias || [],
    redirect: gift_45cardtmoMyoPxmoMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/gift-card.vue").then(m => m.default || m)
  },
  {
    name: feature_45flagsAEnkTJXV99Meta?.name ?? "healthcheck-feature-flags",
    path: feature_45flagsAEnkTJXV99Meta?.path ?? "/healthcheck/feature-flags",
    meta: feature_45flagsAEnkTJXV99Meta || {},
    alias: feature_45flagsAEnkTJXV99Meta?.alias || [],
    redirect: feature_45flagsAEnkTJXV99Meta?.redirect,
    component: () => import("/opt/build/repo/domains/core/pages/healthcheck/feature-flags.vue").then(m => m.default || m)
  },
  {
    name: indexYNInD0yqeIMeta?.name ?? "healthcheck",
    path: indexYNInD0yqeIMeta?.path ?? "/healthcheck",
    meta: indexYNInD0yqeIMeta || {},
    alias: indexYNInD0yqeIMeta?.alias || [],
    redirect: indexYNInD0yqeIMeta?.redirect,
    component: () => import("/opt/build/repo/domains/core/pages/healthcheck/index.vue").then(m => m.default || m)
  },
  {
    name: mockszaKVBZ4zkrMeta?.name ?? "healthcheck-mocks",
    path: mockszaKVBZ4zkrMeta?.path ?? "/healthcheck/mocks",
    meta: mockszaKVBZ4zkrMeta || {},
    alias: mockszaKVBZ4zkrMeta?.alias || [],
    redirect: mockszaKVBZ4zkrMeta?.redirect,
    component: () => import("/opt/build/repo/domains/core/pages/healthcheck/mocks.vue").then(m => m.default || m)
  },
  {
    name: static_45pagesEkYVy0JFNAMeta?.name ?? "healthcheck-static-pages",
    path: static_45pagesEkYVy0JFNAMeta?.path ?? "/healthcheck/static-pages",
    meta: static_45pagesEkYVy0JFNAMeta || {},
    alias: static_45pagesEkYVy0JFNAMeta?.alias || [],
    redirect: static_45pagesEkYVy0JFNAMeta?.redirect,
    component: () => import("/opt/build/repo/domains/core/pages/healthcheck/static-pages.vue").then(m => m.default || m)
  },
  {
    name: statusDsh9ErRLnKMeta?.name ?? "healthcheck-status",
    path: statusDsh9ErRLnKMeta?.path ?? "/healthcheck/status",
    meta: statusDsh9ErRLnKMeta || {},
    alias: statusDsh9ErRLnKMeta?.alias || [],
    redirect: statusDsh9ErRLnKMeta?.redirect,
    component: () => import("/opt/build/repo/domains/core/pages/healthcheck/status.vue").then(m => m.default || m)
  },
  {
    name: translationsK89azthgVZMeta?.name ?? "healthcheck-translations",
    path: translationsK89azthgVZMeta?.path ?? "/healthcheck/translations",
    meta: translationsK89azthgVZMeta || {},
    alias: translationsK89azthgVZMeta?.alias || [],
    redirect: translationsK89azthgVZMeta?.redirect,
    component: () => import("/opt/build/repo/domains/core/pages/healthcheck/translations.vue").then(m => m.default || m)
  },
  {
    name: help60JKLo08atMeta?.name ?? undefined,
    path: help60JKLo08atMeta?.path ?? "/help",
    meta: help60JKLo08atMeta || {},
    alias: help60JKLo08atMeta?.alias || [],
    redirect: help60JKLo08atMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/help.vue").then(m => m.default || m),
    children: [
  {
    name: index3bev93goYsMeta?.name ?? "help-slug-child_slug",
    path: index3bev93goYsMeta?.path ?? ":slug()/:child_slug()",
    meta: index3bev93goYsMeta || {},
    alias: index3bev93goYsMeta?.alias || [],
    redirect: index3bev93goYsMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/help/[slug]/[child_slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexRABbflB1hQMeta?.name ?? "help-slug",
    path: indexRABbflB1hQMeta?.path ?? ":slug()",
    meta: indexRABbflB1hQMeta || {},
    alias: indexRABbflB1hQMeta?.alias || [],
    redirect: indexRABbflB1hQMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/help/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: all_45topicsVt7jfuQxwoMeta?.name ?? "help-all-topics",
    path: all_45topicsVt7jfuQxwoMeta?.path ?? "all-topics",
    meta: all_45topicsVt7jfuQxwoMeta || {},
    alias: all_45topicsVt7jfuQxwoMeta?.alias || [],
    redirect: all_45topicsVt7jfuQxwoMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/help/all-topics.vue").then(m => m.default || m)
  },
  {
    name: contact_45uswxM9cdDGLjMeta?.name ?? "help-contact-us",
    path: contact_45uswxM9cdDGLjMeta?.path ?? "contact-us",
    meta: contact_45uswxM9cdDGLjMeta || {},
    alias: contact_45uswxM9cdDGLjMeta?.alias || [],
    redirect: contact_45uswxM9cdDGLjMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/help/contact-us.vue").then(m => m.default || m)
  },
  {
    name: faqk4PzR6ZMrqMeta?.name ?? "help-faq",
    path: faqk4PzR6ZMrqMeta?.path ?? "faq",
    meta: faqk4PzR6ZMrqMeta || {},
    alias: faqk4PzR6ZMrqMeta?.alias || [],
    redirect: faqk4PzR6ZMrqMeta?.redirect,
    component: () => import("/opt/build/repo/brands/vans/regions/emea/pages/help/faq.vue").then(m => m.default || m)
  },
  {
    name: indexEeWJSz6E2lMeta?.name ?? "help",
    path: indexEeWJSz6E2lMeta?.path ?? "",
    meta: indexEeWJSz6E2lMeta || {},
    alias: indexEeWJSz6E2lMeta?.alias || [],
    redirect: indexEeWJSz6E2lMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/help/index.vue").then(m => m.default || m)
  },
  {
    name: size_45chartsYRYEHRD8EGMeta?.name ?? "help-size-charts",
    path: size_45chartsYRYEHRD8EGMeta?.path ?? "size-charts",
    meta: size_45chartsYRYEHRD8EGMeta || {},
    alias: size_45chartsYRYEHRD8EGMeta?.alias || [],
    redirect: size_45chartsYRYEHRD8EGMeta?.redirect,
    component: () => import("/opt/build/repo/brands/vans/pages/help/size-charts.vue").then(m => m.default || m)
  }
]
  },
  {
    name: location_45selectorRcxhmVvYElMeta?.name ?? "location-selector",
    path: location_45selectorRcxhmVvYElMeta?.path ?? "/location-selector",
    meta: location_45selectorRcxhmVvYElMeta || {},
    alias: location_45selectorRcxhmVvYElMeta?.alias || [],
    redirect: location_45selectorRcxhmVvYElMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/location-selector.vue").then(m => m.default || m)
  },
  {
    name: lower_45envs7iyfzqrC1tMeta?.name ?? "lower-envs",
    path: lower_45envs7iyfzqrC1tMeta?.path ?? "/lower-envs",
    meta: lower_45envs7iyfzqrC1tMeta || {},
    alias: lower_45envs7iyfzqrC1tMeta?.alias || [],
    redirect: lower_45envs7iyfzqrC1tMeta?.redirect,
    component: () => import("/opt/build/repo/domains/core/pages/lower-envs.vue").then(m => m.default || m)
  },
  {
    name: newsletter_45subscriberla6JKAnMLBMeta?.name ?? "newsletter-subscriber",
    path: newsletter_45subscriberla6JKAnMLBMeta?.path ?? "/newsletter-subscriber",
    meta: newsletter_45subscriberla6JKAnMLBMeta || {},
    alias: newsletter_45subscriberla6JKAnMLBMeta?.alias || [],
    redirect: newsletter_45subscriberla6JKAnMLBMeta?.redirect,
    component: () => import("/opt/build/repo/brands/vans/regions/emea/pages/newsletter-subscriber.vue").then(m => m.default || m)
  },
  {
    name: order_45confirmation2zFGjSoOQrMeta?.name ?? "order-confirmation",
    path: order_45confirmation2zFGjSoOQrMeta?.path ?? "/order-confirmation",
    meta: order_45confirmation2zFGjSoOQrMeta || {},
    alias: order_45confirmation2zFGjSoOQrMeta?.alias || [],
    redirect: order_45confirmation2zFGjSoOQrMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/order-confirmation.vue").then(m => m.default || m)
  },
  {
    name: order_45details2hEewW5V7XMeta?.name ?? "order-details",
    path: order_45details2hEewW5V7XMeta?.path ?? "/order-details",
    meta: order_45details2hEewW5V7XMeta || {},
    alias: order_45details2hEewW5V7XMeta?.alias || [],
    redirect: order_45details2hEewW5V7XMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/order-details.vue").then(m => m.default || m)
  },
  {
    name: order_45statusSEiCEG4MyaMeta?.name ?? "order-status",
    path: order_45statusSEiCEG4MyaMeta?.path ?? "/order-status",
    meta: order_45statusSEiCEG4MyaMeta || {},
    alias: order_45statusSEiCEG4MyaMeta?.alias || [],
    redirect: order_45statusSEiCEG4MyaMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/order-status.vue").then(m => m.default || m)
  },
  {
    name: otw_45by_45vansdvfuOelxAIMeta?.name ?? "otw-by-vans",
    path: otw_45by_45vansdvfuOelxAIMeta?.path ?? "/otw-by-vans",
    meta: otw_45by_45vansdvfuOelxAIMeta || {},
    alias: otw_45by_45vansdvfuOelxAIMeta?.alias || [],
    redirect: otw_45by_45vansdvfuOelxAIMeta?.redirect,
    component: () => import("/opt/build/repo/brands/vans/regions/emea/pages/otw-by-vans.vue").then(m => m.default || m)
  },
  {
    name: gift_45card_45_91slug_93aBTCyRnHqOMeta?.name ?? "p-catalog-gift-card-slug",
    path: gift_45card_45_91slug_93aBTCyRnHqOMeta?.path ?? "/p/:catalog(.*)*/gift-card-:slug()",
    meta: gift_45card_45_91slug_93aBTCyRnHqOMeta || {},
    alias: gift_45card_45_91slug_93aBTCyRnHqOMeta?.alias || [],
    redirect: gift_45card_45_91slug_93aBTCyRnHqOMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/p/[...catalog]/gift-card-[slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_938XUmtihIQsMeta?.name ?? "p-slug",
    path: _91_46_46_46slug_938XUmtihIQsMeta?.path ?? "/p/:slug(.*)*",
    meta: _91_46_46_46slug_938XUmtihIQsMeta || {},
    alias: _91_46_46_46slug_938XUmtihIQsMeta?.alias || [],
    redirect: _91_46_46_46slug_938XUmtihIQsMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/p/[...slug].vue").then(m => m.default || m)
  },
  {
    name: pillowheatec6W0gUBGYMeta?.name ?? "pillowheat",
    path: pillowheatec6W0gUBGYMeta?.path ?? "/pillowheat",
    meta: pillowheatec6W0gUBGYMeta || {},
    alias: pillowheatec6W0gUBGYMeta?.alias || [],
    redirect: pillowheatec6W0gUBGYMeta?.redirect,
    component: () => import("/opt/build/repo/brands/vans/regions/emea/pages/pillowheat.vue").then(m => m.default || m)
  },
  {
    name: poslogonP3pXPibJHEMeta?.name ?? "poslogon",
    path: poslogonP3pXPibJHEMeta?.path ?? "/poslogon",
    meta: poslogonP3pXPibJHEMeta || {},
    alias: poslogonP3pXPibJHEMeta?.alias || [],
    redirect: poslogonP3pXPibJHEMeta?.redirect,
    component: () => import("/opt/build/repo/brands/vans/pages/poslogon.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordhnp3THUaMLMeta?.name ?? "reset-password",
    path: reset_45passwordhnp3THUaMLMeta?.path ?? "/reset-password",
    meta: reset_45passwordhnp3THUaMLMeta || {},
    alias: reset_45passwordhnp3THUaMLMeta?.alias || [],
    redirect: reset_45passwordhnp3THUaMLMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: return_45labelNxjSTzFdk0Meta?.name ?? "return-label",
    path: return_45labelNxjSTzFdk0Meta?.path ?? "/return-label",
    meta: return_45labelNxjSTzFdk0Meta || {},
    alias: return_45labelNxjSTzFdk0Meta?.alias || [],
    redirect: return_45labelNxjSTzFdk0Meta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/return-label.vue").then(m => m.default || m)
  },
  {
    name: _91productId_93AKFxWRSIxWMeta?.name ?? "reviews-productId",
    path: _91productId_93AKFxWRSIxWMeta?.path ?? "/reviews/:productId()",
    meta: _91productId_93AKFxWRSIxWMeta || {},
    alias: _91productId_93AKFxWRSIxWMeta?.alias || [],
    redirect: _91productId_93AKFxWRSIxWMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/reviews/[productId].vue").then(m => m.default || m)
  },
  {
    name: index0fogzGIqf7Meta?.name ?? "sandbox-cms",
    path: index0fogzGIqf7Meta?.path ?? "/sandbox/cms",
    meta: index0fogzGIqf7Meta || {},
    alias: index0fogzGIqf7Meta?.alias || [],
    redirect: index0fogzGIqf7Meta?.redirect,
    component: () => import("/opt/build/repo/domains/content/pages/sandbox/cms/index.vue").then(m => m.default || m)
  },
  {
    name: indexOjBbltyRRZMeta?.name ?? "sandbox-dialogs",
    path: indexOjBbltyRRZMeta?.path ?? "/sandbox/dialogs",
    meta: indexOjBbltyRRZMeta || {},
    alias: indexOjBbltyRRZMeta?.alias || [],
    redirect: indexOjBbltyRRZMeta?.redirect,
    component: () => import("/opt/build/repo/domains/content/pages/sandbox/dialogs/index.vue").then(m => m.default || m)
  },
  {
    name: indexYytI4mQazyMeta?.name ?? "sandbox-monetate",
    path: indexYytI4mQazyMeta?.path ?? "/sandbox/monetate",
    meta: indexYytI4mQazyMeta || {},
    alias: indexYytI4mQazyMeta?.alias || [],
    redirect: indexYytI4mQazyMeta?.redirect,
    component: () => import("/opt/build/repo/domains/content/pages/sandbox/monetate/index.vue").then(m => m.default || m)
  },
  {
    name: dashboardVdaazQkT64Meta?.name ?? "sandbox-preview-account-dashboard",
    path: dashboardVdaazQkT64Meta?.path ?? "/sandbox/preview/account/dashboard",
    meta: dashboardVdaazQkT64Meta || {},
    alias: dashboardVdaazQkT64Meta?.alias || [],
    redirect: dashboardVdaazQkT64Meta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/sandbox/preview/account/dashboard.vue").then(m => m.default || m)
  },
  {
    name: profile1OppCRoqrFMeta?.name ?? "sandbox-preview-account-profile",
    path: profile1OppCRoqrFMeta?.path ?? "/sandbox/preview/account/profile",
    meta: profile1OppCRoqrFMeta || {},
    alias: profile1OppCRoqrFMeta?.alias || [],
    redirect: profile1OppCRoqrFMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/sandbox/preview/account/profile.vue").then(m => m.default || m)
  },
  {
    name: checkoutNnt8GaEkPWMeta?.name ?? "sandbox-preview-checkout",
    path: checkoutNnt8GaEkPWMeta?.path ?? "/sandbox/preview/checkout",
    meta: checkoutNnt8GaEkPWMeta || {},
    alias: checkoutNnt8GaEkPWMeta?.alias || [],
    redirect: checkoutNnt8GaEkPWMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/sandbox/preview/checkout.vue").then(m => m.default || m)
  },
  {
    name: order_45confirmationQ6TY8ONuoZMeta?.name ?? "sandbox-preview-order-confirmation",
    path: order_45confirmationQ6TY8ONuoZMeta?.path ?? "/sandbox/preview/order-confirmation",
    meta: order_45confirmationQ6TY8ONuoZMeta || {},
    alias: order_45confirmationQ6TY8ONuoZMeta?.alias || [],
    redirect: order_45confirmationQ6TY8ONuoZMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/sandbox/preview/order-confirmation.vue").then(m => m.default || m)
  },
  {
    name: searchxnc5Nq4Lb2Meta?.name ?? "search-parent",
    path: searchxnc5Nq4Lb2Meta?.path ?? "/search",
    meta: searchxnc5Nq4Lb2Meta || {},
    alias: searchxnc5Nq4Lb2Meta?.alias || [],
    redirect: searchxnc5Nq4Lb2Meta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/search.vue").then(m => m.default || m),
    children: [
  {
    name: articlesU210RWMfaRMeta?.name ?? "search-articles",
    path: articlesU210RWMfaRMeta?.path ?? "articles",
    meta: articlesU210RWMfaRMeta || {},
    alias: articlesU210RWMfaRMeta?.alias || [],
    redirect: articlesU210RWMfaRMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/search/articles.vue").then(m => m.default || m)
  },
  {
    name: indexKjUEhFFlYyMeta?.name ?? "search",
    path: indexKjUEhFFlYyMeta?.path ?? "",
    meta: indexKjUEhFFlYyMeta || {},
    alias: indexKjUEhFFlYyMeta?.alias || [],
    redirect: indexKjUEhFFlYyMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/search/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: sign_45invOXFFa0gzPMeta?.name ?? "sign-in",
    path: sign_45invOXFFa0gzPMeta?.path ?? "/sign-in",
    meta: sign_45invOXFFa0gzPMeta || {},
    alias: sign_45invOXFFa0gzPMeta?.alias || [],
    redirect: sign_45invOXFFa0gzPMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/sign-in.vue").then(m => m.default || m)
  },
  {
    name: since_4566JbpcI0GUL7Meta?.name ?? "since-66",
    path: since_4566JbpcI0GUL7Meta?.path ?? "/since-66",
    meta: since_4566JbpcI0GUL7Meta || {},
    alias: since_4566JbpcI0GUL7Meta?.alias || [],
    redirect: since_4566JbpcI0GUL7Meta?.redirect,
    component: () => import("/opt/build/repo/brands/vans/pages/since-66.vue").then(m => m.default || m)
  },
  {
    name: size_45chart0T7nwjfX8oMeta?.name ?? "size-chart",
    path: size_45chart0T7nwjfX8oMeta?.path ?? "/size-chart",
    meta: size_45chart0T7nwjfX8oMeta || {},
    alias: size_45chart0T7nwjfX8oMeta?.alias || [],
    redirect: size_45chart0T7nwjfX8oMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/size-chart.vue").then(m => m.default || m)
  },
  {
    name: footerAUtE5rq5gqMeta?.name ?? "soci-footer",
    path: footerAUtE5rq5gqMeta?.path ?? "/soci/footer",
    meta: footerAUtE5rq5gqMeta || {},
    alias: footerAUtE5rq5gqMeta?.alias || [],
    redirect: footerAUtE5rq5gqMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/soci/footer.vue").then(m => m.default || m)
  },
  {
    name: frame382Y1ymrZaMeta?.name ?? "soci-frame",
    path: frame382Y1ymrZaMeta?.path ?? "/soci/frame",
    meta: frame382Y1ymrZaMeta || {},
    alias: frame382Y1ymrZaMeta?.alias || [],
    redirect: frame382Y1ymrZaMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/soci/frame.vue").then(m => m.default || m)
  },
  {
    name: headerR4riDyN3LsMeta?.name ?? "soci-header",
    path: headerR4riDyN3LsMeta?.path ?? "/soci/header",
    meta: headerR4riDyN3LsMeta || {},
    alias: headerR4riDyN3LsMeta?.alias || [],
    redirect: headerR4riDyN3LsMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/soci/header.vue").then(m => m.default || m)
  },
  {
    name: store_45locatortqupMGLkzCMeta?.name ?? "store-locator",
    path: store_45locatortqupMGLkzCMeta?.path ?? "/store-locator",
    meta: store_45locatortqupMGLkzCMeta || {},
    alias: store_45locatortqupMGLkzCMeta?.alias || [],
    redirect: store_45locatortqupMGLkzCMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/store-locator.vue").then(m => m.default || m)
  },
  {
    name: student_45discountBs4TB40tHtMeta?.name ?? "student-discount",
    path: student_45discountBs4TB40tHtMeta?.path ?? "/student-discount",
    meta: student_45discountBs4TB40tHtMeta || {},
    alias: student_45discountBs4TB40tHtMeta?.alias || [],
    redirect: student_45discountBs4TB40tHtMeta?.redirect,
    component: () => import("/opt/build/repo/brands/vans/regions/emea/pages/student-discount.vue").then(m => m.default || m)
  },
  {
    name: sustainabilitylibbGBqpSYMeta?.name ?? "sustainability",
    path: sustainabilitylibbGBqpSYMeta?.path ?? "/sustainability",
    meta: sustainabilitylibbGBqpSYMeta || {},
    alias: sustainabilitylibbGBqpSYMeta?.alias || [],
    redirect: sustainabilitylibbGBqpSYMeta?.redirect,
    component: () => import("/opt/build/repo/brands/vans/pages/sustainability.vue").then(m => m.default || m)
  },
  {
    name: _91slug_939yy7wVfmBHMeta?.name ?? "tag-slug",
    path: _91slug_939yy7wVfmBHMeta?.path ?? "/tag/:slug()",
    meta: _91slug_939yy7wVfmBHMeta || {},
    alias: _91slug_939yy7wVfmBHMeta?.alias || [],
    redirect: _91slug_939yy7wVfmBHMeta?.redirect,
    component: () => import("/opt/build/repo/brands/vans/pages/tag/[slug].vue").then(m => m.default || m)
  },
  {
    name: westendxDDwHHaE22Meta?.name ?? "westend",
    path: westendxDDwHHaE22Meta?.path ?? "/westend",
    meta: westendxDDwHHaE22Meta || {},
    alias: westendxDDwHHaE22Meta?.alias || [],
    redirect: westendxDDwHHaE22Meta?.redirect,
    component: () => import("/opt/build/repo/brands/vans/regions/emea/pages/westend.vue").then(m => m.default || m)
  },
  {
    name: write_45a_45reviewb5NDvBQXhOMeta?.name ?? "write-a-review",
    path: write_45a_45reviewb5NDvBQXhOMeta?.path ?? "/write-a-review",
    meta: write_45a_45reviewb5NDvBQXhOMeta || {},
    alias: write_45a_45reviewb5NDvBQXhOMeta?.alias || [],
    redirect: write_45a_45reviewb5NDvBQXhOMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/write-a-review.vue").then(m => m.default || m)
  }
]